// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@import "./utils/mixins";
@import "./utils/helpers";

/* ================== Name App ==================== */
$app: sonar;

:root {
  // Font Families
  --font-ui: "Fira Sans", "FiraSans", Helvetica, sans-serif;
  --font-icon: "mtech" !important;

  // Font Sizes
  --font-m: 0.875rem;
  --font-xxs: 0.5rem; // 8px
  --font-xs: 0.625rem; // 10px
  --font-s: 0.75rem; // 12px
  --font-l: 1rem; // 16px
  --font-xl: 1.125rem; // 18px
  --font-xxl: 1.3125rem; // 21px
  --font-xxxl: 1.5rem; // 24px
  --font-xxxxl: 2.25rem; // 36px

  // Images path
  --app: sonar;
  --appPath: "assets/sonar/";

  // Colors
  --hx-white: #fff;
  --hx-white-rgb: 255, 255, 255;
  --hx-black: #000000;
  --hx-black-rgb: 0, 0, 0;

  --hx-ui-900: #0a1232;
  --hx-ui-800: #111a4b;
  --hx-ui-800-rgb: 17, 26, 75;
  --hx-ui-700: #162364;
  --hx-ui-600: #1d2f86;
  --hx-ui-500: #2d52c7;
  --hx-ui-400: #397bea;
  --hx-ui-300: #7eaaed;
  --hx-ui-200: #c8d6ef;
  --hx-ui-100: #eff2fa;
  --hx-ui-50: #f4f8fc;

  --hx-gray-900: #212121;
  --hx-gray-900-rgb: 33, 33, 33;
  --hx-gray-800: #525252;
  --hx-gray-700: #757575;
  --hx-gray-600: #adadad;
  --hx-gray-500: #c2c2c2;
  --hx-gray-400: #d6d6d6;
  --hx-gray-300: #e0e0e0;
  --hx-gray-200: #ebebeb;
  --hx-gray-100: #f5f5f5;

  --hx-accent-700: #0a56b3;
  --hx-accent-400: #0c75f6;
  --hx-accent-400-rgb: 12, 117, 246;
  --hx-accent-200: #a6ccfb;
  --hx-accent-100: #e7f2fe;

  --hx-alert-700: #b02032;
  --hx-alert-400: #d7263d;
  --hx-alert-400-rgb: 215, 38, 61;
  --hx-alert-300: #e98895;
  --hx-alert-100: #f7d7db;

  --hx-success-700: #5ba229;
  --hx-success-400: #6ec932;
  --hx-success-300: #afe28d;
  --hx-success-100: #ddf2ce;

  --hx-warning-700: #f2a100;
  --hx-warning-400: #ffb915;
  --hx-warning-300: #ffd95c;
  --hx-warning-100: #fff0c2;

  --hx-info-700: #355de7;
  --hx-info-400: #6885ed;
  --hx-info-300: #a4b4f4;
  --hx-info-100: #dae1fb;

  --hx-attention-700: #f53900;
  --hx-attention-400: #ff5f2e;
  --hx-attention-300: #ff825c;
  --hx-attention-100: #fec0ad;

  --hx-aux-1: #ffb915;
  --hx-aux-1-100: #fff7e8;
  --hx-aux-1-200: #ffd95c;
  --hx-aux-1-400: var(--hx-aux-1);
  --hx-aux-1-700: #f2a100;

  --hx-aux-2: #7fabcc;
  --hx-aux-2-100: #f0f5f9;
  --hx-aux-2-200: #a7c4dc;
  --hx-aux-2-400: var(--hx-aux-2);
  --hx-aux-2-700: #2f5675;

  --hx-aux-3: #ff5f2e;
  --hx-aux-3-100: #ffece6;
  --hx-aux-3-200: #ff825c;
  --hx-aux-3-400: var(--hx-aux-3);
  --hx-aux-3-700: #f53900;

  --hx-aux-4: #6885ed;
  --hx-aux-4-100: #e8ecfc;
  --hx-aux-4-200: #a4b4f4;
  --hx-aux-4-400: var(--hx-aux-4);
  --hx-aux-4-700: #355de7;

  --hx-aux-5: #ea062c;
  --hx-aux-5-100: #fee6ea;
  --hx-aux-5-200: #fb5f76;
  --hx-aux-5-400: var(--hx-aux-5);
  --hx-aux-5-700: #c70522;

  --hx-aux-6: #90e05c;
  --hx-aux-6-100: #e9f8dd;
  --hx-aux-6-200: #c4efa9;
  --hx-aux-6-400: var(--hx-aux-6);
  --hx-aux-6-700: #5ba229;

  --hx-aux-7: #7945dd;
  --hx-aux-7-100: #e6dcf9;
  --hx-aux-7-200: #9e75e6;
  --hx-aux-7-400: var(--hx-aux-7);
  --hx-aux-7-700: #5923be;

  --hx-aux-8: #6ec7ed;
  --hx-aux-8-100: #dbf0fb;
  --hx-aux-8-200: #b6e3f6;
  --hx-aux-8-400: var(--hx-aux-7);
  --hx-aux-8-700: #1793c8;

  --hx-aux-9: #fa7268;
  --hx-aux-9-100: #f7dada;
  --hx-aux-9-200: #fcb1b1;
  --hx-aux-9-400: var(--hx-aux-7);
  --hx-aux-9-700: #d85050;

  --hx-aux-10: #178c74;
  --hx-aux-10-100: #effcf9;
  --hx-aux-10-200: #b9f3e8;
  --hx-aux-10-400: var(--hx-aux-7);
  --hx-aux-10-700: #105e4f;

  --hx-aux-11: #ed1e79;
  --hx-aux-11-100: #fde8f6;
  --hx-aux-11-200: #d96cf4;
  --hx-aux-11-400: var(--hx-aux-7);
  --hx-aux-11-700: #b5064d;

  --hx-aux-12: #ae3fc9;
  --hx-aux-12-100: #f4d9fc;
  --hx-aux-12-200: #9e75e6;
  --hx-aux-12-400: var(--hx-aux-7);
  --hx-aux-12-700: #690c7e;

  --hx-aux-13: #bdd435;
  --hx-aux-13-100: #eff5cc;
  --hx-aux-13-200: #d9e887;
  --hx-aux-13-400: var(--hx-aux-7);
  --hx-aux-13-700: #95a924;

  --hx-aux-14: #16d8d3;
  --hx-aux-14-100: #d9fbfa;
  --hx-aux-14-200: #7df2ee;
  --hx-aux-14-400: var(--hx-aux-7);
  --hx-aux-14-700: #15bab4;

  // Borders
  --hx-border: var(--hx-gray-300);
  --hx-border-radius: 4px;

  // Text Colors
  --hx-emphasis: rgba(0, 0, 0, 0.87);
  --hx-emphasis--dark: var(--hx-white);
  --hx-normal: rgba(0, 0, 0, 0.6);
  --hx-normal--dark: rgba(255, 255, 255, 0.8);
  --hx-disabled: rgba(0, 0, 0, 0.38);
  --hx-disabled--dark: rgba(255, 255, 255, 0.54);

  --hx-overlay: rgba(33, 33, 33, 0.5);

  // Box Shadows
  --hx-shadow-dropdown: 0px 6px 8px -2px rgba(0, 0, 0, 0.08),
    -4px 0px 4px -2px rgba(0, 0, 0, 0.06),
    4px -4px 4px -2px rgba(184, 77, 77, 0.06);
  --hx-shadow-navigation: 7px 7px 10px 0 rgba(0, 0, 0, 0.05);
  --hx-shadow-card: 4px 4px 4px -2px rgba(0, 0, 0, 0.08);
  --hx-shadow-card-hover: 0 0 12px 2px rgba(0, 0, 0, 0.12);
  --hx-shadow-button: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  --hx-shadow-button-active: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --hx-shadow-toaster: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);

  // Transitions
  --hx-transition-time: 0.25s;
  --hx-transition-ease: ease-out;
  --hx-easeQuintOut: cubic-bezier(0.23, 1, 0.32, 1);

  /** Ionic CSS Variables **/
  --ion-font-family: var(--font-ui);

  /** primary **/
  --ion-color-primary: var(--hx-accent-400);
  --ion-color-primary-rgb: var(--hx-accent-400-rgb);
  --ion-color-primary-contrast: var(--white);
  --ion-color-primary-contrast-rgb: var(--white-rgb);
  --ion-color-primary-shade: var(--hx-accent-300);
  --ion-color-primary-tint: var(--hx-accent-700);

  /** secondary (Should not be used) **/
  --ion-color-secondary: var(--hx-ui-700);
  --ion-color-secondary-rgb: var(--hx-ui-700-rgb);
  --ion-color-secondary-contrast: var(--white);
  --ion-color-secondary-contrast-rgb: var(--white-rgb);
  --ion-color-secondary-shade: var(--hx-ui-900);
  --ion-color-secondary-tint: var(--hx-ui-600);

  /** tertiary (Should be used as clear mode only) **/
  --ion-color-tertiary: var(--hx-gray-800);
  --ion-color-tertiary-rgb: 82, 82, 82;
  --ion-color-tertiary-contrast: var(--white);
  --ion-color-tertiary-contrast-rgb: var(--white-rgb);
  --ion-color-tertiary-shade: var(--hx-gray-900);
  --ion-color-tertiary-tint: var(--hx-gray-600);

  /** success **/
  --ion-color-success: var(--hx-success-400);
  --ion-color-success-rgb: var(--hx-success-400-rgb);
  --ion-color-success-contrast: var(--white);
  --ion-color-success-contrast-rgb: var(--white-rgb);
  --ion-color-success-shade: var(--hx-success-700);
  --ion-color-success-tint: var(--hx-success-300);

  /** warning **/
  --ion-color-warning: var(--hx-warning-400);
  --ion-color-warning-rgb: var(--hx-warning-400-rgb);
  --ion-color-warning-contrast: var(--white);
  --ion-color-warning-contrast-rgb: var(--white-rgb);
  --ion-color-warning-shade: var(--hx-warning-700);
  --ion-color-warning-tint: var(--hx-warning-300);

  /** danger **/
  --ion-color-danger: var(--hx-alert-400);
  --ion-color-danger-rgb: var(--hx-alert-400-rgb);
  --ion-color-danger-contrast: var(--white);
  --ion-color-danger-contrast-rgb: var(--white-rgb);
  --ion-color-danger-shade: var(--hx-warning-700);
  --ion-color-danger-tint: var(--hx-warning-300);

  /** dark **/
  --ion-color-dark: var(--hx-ui-900);
  --ion-color-dark-rgb: var(--hx-ui-900-rgb);
  --ion-color-dark-contrast: var(--white);
  --ion-color-dark-contrast-rgb: var(--white-rgb);
  --ion-color-dark-shade: var(--hx-ui-1000);
  --ion-color-dark-tint: var(--hx-ui-800);

  /** medium (Should not be used) **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: var(--white);
  --ion-color-medium-contrast-rgb: var(--white-rgb);
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: var(--hx-ui-100);
  --ion-color-light-rgb: var(--hx-ui-100-rgb);
  --ion-color-light-contrast: var(--black);
  --ion-color-light-contrast-rgb: var(--black-rgb);
  --ion-color-light-shade: var(--hx-ui-200);
  --ion-color-light-tint: var(--hx-ui-50);
}

* {
  font-family: "Fira Sans", "FiraSans", Helvetica, sans-serif;
  font-variant-ligatures: none;
}

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
}

.transparent {
  background-color: transparent !important;

  body {
    --ion-background-color: transparent !important;
  }
}

.md body:not(.dark),
.ios body:not(.dark) {
  /* Set the background of the entire app */
  --ion-background-color: var(--hx-ui-100);
  --ion-tab-bar-background: var(--white);
  --ion-text-color: rgba(var(--hx-emphasis), 0.87);

  ion-footer {
    background-color: var(--white);
  }
}

ion-header {
  --background: var(--white);
  box-shadow: var(--hx-shadow-navigation) !important;

  &.header-md:after {
    background-image: none !important;
  }

  ion-toolbar {
    ion-buttons {
      .navigation {
        &__icon {
          color: #ada9b5;
          font-size: 24px;
        }
      }
    }
  }

  ion-title {
    .navigation__title {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      ion-select {
        min-height: auto;
      }

      ion-select::part(container) {
        display: flex;
        justify-content: center;
        width: 100% !important;
      }

      ion-select::part(text) {
        flex: none !important;
      }

      ion-select::part(icon) {
        opacity: 1 !important;
      }

      .navigation__subtitle {
        padding-top: 4px;
        text-align: center;
      }
    }
  }

  ion-segment {
    --background: var(--hx-ui-700);
    border-radius: 0px;

    ion-segment-button {
      --background-hover-opacity: 0;
      --color: var(--hx-normal--dark);
      --color-hover: var(--hx-normal);
    }
  }
}

.content-md {
  background-color: var(--hx-ui-100);
}

.empty-state {
  align-content: center;
  align-items: center;
  background: var(--hx-ui-100);
  display: flex;
  flex-direction: column;
  height: 100%;

  &__wrapper {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 1rem;
    text-align: center;
    color: var(--hx-normal);
  }

  &__image {
    height: 200px;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
  }

  &__message {
    font-size: 0.8rem;
  }
}

.tabs-detail {
  padding: 1rem 0;

  .tabs-wrapper {
    display: flex;
    height: auto;
    justify-content: center;
    width: 100%;

    ion-segment-button {
      @include transition-default(color);
      background-color: var(--hx-white);
      border: 1px solid transparent;
      box-shadow: var(--hx-shadow-button);
      color: var(--hx-normal) !important;
      cursor: pointer;
      display: flex;
      font-size: 12px;
      height: 40px;
      margin: 0 -2px;
      max-width: 140px;
      min-width: 40px;
      text-transform: none;
    }

    ion-segment-button:first-child {
      border-radius: 4px 0 0 4px;
      text-align: right;
    }

    ion-segment-button:last-child {
      border-radius: 0 4px 4px 0;
      text-align: left;
    }

    .active,
    .segment-button-checked {
      --indicator-color: transparent !important;
      border-color: var(--hx-gray-300) !important;
      border: 1px solid;
      box-shadow: none !important;
      color: var(--hx-accent-400) !important;
    }

    .tabs-title {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;

      [class*="ami-"] {
        font-size: 20px;
        margin: auto;
      }
    }
  }
}

ion-tab-bar {
  --background: #f8f8f8;

  ion-tab-button {
    --background: #f8f8f8;

    &:not(.tab-selected) {
      color: var(--hx-normal);
    }

    i {
      font-size: 1.5rem;
    }

    ion-label {
      font-size: 0.8rem;
    }
  }
}

ion-action-sheet {
  .action-sheet-selected {
    background-color: var(--hx-ui-200) !important;
    box-shadow: inset 2px 0 0 var(--hx-accent-400);
    color: var(--hx-emphasis);
    font-weight: 400 !important;
  }
}

ion-modal.filter-modal {
  padding-bottom: var(--ion-safe-area-bottom);
  padding-top: var(--ion-safe-area-top);
}

ion-modal.sort-modal {
  padding-bottom: var(--ion-safe-area-bottom);
  padding-top: var(--ion-safe-area-top);

  ion-backdrop {
    visibility: visible;
  }

  .modal-wrapper {
    bottom: 0 !important;
    height: auto;
    min-width: 100%;
    position: absolute !important;

    &,
    .ion-page,
    .ion-page .container,
    .ion-page .container .scroll-content {
      contain: content;
      left: auto;
      position: relative;
      top: auto;
    }

    .ion-page {
      display: block;
      overflow-y: auto;
    }

    ion-content {
      min-height: 400px;
    }
  }
}

ion-alert.setting-select .alert-wrapper {
  --background: var(--hx-white) !important;
  background: var(--hx-white) !important;
}

ion-alert.setting-select
  .select-interface-option[aria-checked="true"]
  .alert-radio-label {
  --color: var(--hx-accent-400) !important;
  color: var(--hx-accent-400) !important;
}

ion-alert.setting-select .alert-radio-icon.sc-ion-alert-md {
  left: 1rem;
}

ion-alert.setting-select .alert-radio-label.sc-ion-alert-md {
  padding-inline-start: 2rem;
}

ion-popover.sensor-options {
  .popover__options {
    list-style: none;
    padding: 0px;

    .popover__option {
      padding-left: 1rem;
      cursor: pointer;
    }
  }
}

ion-searchbar {
  .searchbar-cancel-button.sc-ion-searchbar-md {
    padding-left: 8px;
  }
}

.kpiLabel_medium {
  .house-card {
    &__kpi {
      &-age,
      &-bird,
      &-livability {
        &-label {
          font-size: 11px !important;
        }
      }
    }

    .sensors__content-6,
    .sensors__content-10 {
      .sensor__item {
        .sensor__item-kpi {
          &-label {
            font-size: 11px !important;
          }
        }
      }
    }
  }
}

.kpiLabel_large {
  .house-card {
    &__kpi {
      &-age,
      &-bird,
      &-livability {
        &-label {
          font-size: 12px !important;
        }
      }
    }

    .sensors__content-6,
    .sensors__content-10 {
      .sensor__item {
        .sensor__item-kpi {
          &-label {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

.kpiLabel_medium {
  .card-kpis {
    .card-kpis__title-label,
    .card-kpis__label {
      font-size: 11px !important;
    }
  }
}

.kpiLabel_large {
  .card-kpis {
    .card-kpis__title-label,
    .card-kpis__label {
      font-size: 12px !important;
    }
  }
}

ion-action-sheet {
  .action-sheet-group.sc-ion-action-sheet-md:last-child {
    padding-bottom: var(--ion-safe-area-bottom);
  }
}

.score-alert .alert-wrapper {
  .alert-message {
    padding-bottom: 0 !important;

    > .header {
      width: 100%;
      padding-bottom: 1rem;

      p {
        color: var(--hx-normal);
        margin: 0;
        padding-bottom: 0.5rem;
      }
    }

    > .footer {
      width: 100%;

      p {
        color: var(--hx-emphasis);
        font-weight: 500;
        margin: 0;
      }
    }
  }
}
