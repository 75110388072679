@font-face {
  font-family: "latoblack";
  src: url("./lato-black-webfont.woff2") format("woff2"),
    url("./lato-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "latoblack_italic";
  src: url("./lato-blackitalic-webfont.woff2") format("woff2"),
    url("./lato-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "latobold";
  src: url("./lato-bold-webfont.woff2") format("woff2"),
    url("./lato-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "latobold_italic";
  src: url("./lato-bolditalic-webfont.woff2") format("woff2"),
    url("./lato-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "latohairline";
  src: url("./lato-hairline-webfont.woff2") format("woff2"),
    url("./lato-hairline-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "latohairline_italic";
  src: url("./lato-hairlineitalic-webfont.woff2") format("woff2"),
    url("./lato-hairlineitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "latoitalic";
  src: url("./lato-italic-webfont.woff2") format("woff2"),
    url("./lato-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "latolight";
  src: url("./lato-light-webfont.woff2") format("woff2"),
    url("./lato-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "latolight_italic";
  src: url("./lato-lightitalic-webfont.woff2") format("woff2"),
    url("./lato-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "latoregular";
  src: url("./lato-regular-webfont.woff2") format("woff2"),
    url("./lato-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
