@import "mtech-icons";

@font-face {
  font-family: 'mtech';
  src:  url('mtech.eot?gy864n');
  src:  url('mtech.eot?gy864n#iefix') format('embedded-opentype'),
    url('mtech.ttf?gy864n') format('truetype'),
    url('mtech.woff?gy864n') format('woff'),
    url('mtech.svg?gy864n#mtech') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ami-"], [class*=" ami-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "mtech" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ami-xs {
  font-size: 0.8rem !important;
}
.ami-xs {
  font-size: 0.875rem !important;
}
.ami-m {
  font-size: 1rem !important;
}
.ami-l {
  font-size: 1.125rem !important;
}
.ami-xl {
  font-size: 1.25rem !important;
}
.ami-xxl {
  font-size: 1.5rem !important;
}

@function unicode($str) {
  @return unquote('"') + $str + unquote('"');
}

.ami-xs {
  font-size: 0.8rem !important;
}
.ami-xs {
  font-size: 0.875rem !important;
}
.ami-m {
  font-size: 1rem !important;
}
.ami-l {
  font-size: 1.125rem !important;
}
.ami-xl {
  font-size: 1.25rem !important;
}
.ami-xxl {
  font-size: 1.5rem !important;
}

@function unicode($str) {
  @return unquote('"') + $str + unquote('"');
}

.ami-download-text {
  &:before {
    content: $ami-download-text; 
  }
}
.ami-open-right {
  &:before {
    content: $ami-open-right; 
  }
}
.ami-attachments {
  &:before {
    content: $ami-attachments; 
  }
}
.ami-collapse-panel {
  &:before {
    content: $ami-collapse-panel; 
  }
}
.ami-custom {
  &:before {
    content: $ami-custom; 
  }
}
.ami-expand-panel {
  &:before {
    content: $ami-expand-panel; 
  }
}
.ami-my-workspace {
  &:before {
    content: $ami-my-workspace; 
  }
}
.ami-swine-scp {
  &:before {
    content: $ami-swine-scp; 
  }
}
.ami-background-jobs {
  &:before {
    content: $ami-background-jobs; 
  }
}
.ami-swine-configuration {
  &:before {
    content: $ami-swine-configuration; 
  }
}
.ami-swine-dashboard {
  &:before {
    content: $ami-swine-dashboard; 
  }
}
.ami-swine-log-file {
  &:before {
    content: $ami-swine-log-file; 
  }
}
.ami-swine-placement-projection {
  &:before {
    content: $ami-swine-placement-projection; 
  }
}
.ami-swine-profile {
  &:before {
    content: $ami-swine-profile; 
  }
}
.ami-interface-batches {
  &:before {
    content: $ami-interface-batches; 
  }
}
.ami-report-batches {
  &:before {
    content: $ami-report-batches; 
  }
}
.ami-scan-barcode {
  &:before {
    content: $ami-scan-barcode; 
  }
}
.ami-scan-qr {
  &:before {
    content: $ami-scan-qr; 
  }
}
.ami-scan-wand-tool {
  &:before {
    content: $ami-scan-wand-tool; 
  }
}
.ami-volume-off {
  &:before {
    content: $ami-volume-off; 
  }
}
.ami-layer-configuration {
  &:before {
    content: $ami-layer-configuration; 
  }
}
.ami-layer-dashboard {
  &:before {
    content: $ami-layer-dashboard; 
  }
}
.ami-layer-flocks {
  &:before {
    content: $ami-layer-flocks; 
  }
}
.ami-layer-log-file {
  &:before {
    content: $ami-layer-log-file; 
  }
}
.ami-layer-profiles {
  &:before {
    content: $ami-layer-profiles; 
  }
}
.ami-animal-welfare-index {
  &:before {
    content: $ami-animal-welfare-index; 
  }
}
.ami-distress {
  &:before {
    content: $ami-distress; 
  }
}
.ami-movement {
  &:before {
    content: $ami-movement; 
  }
}
.ami-breed-wean-right {
  &:before {
    content: $ami-breed-wean-right; 
  }
}
.ami-breed-wean-left {
  &:before {
    content: $ami-breed-wean-left; 
  }
}
.ami-check {
  &:before {
    content: $ami-check; 
  }
}
.ami-delete-all {
  &:before {
    content: $ami-delete-all; 
  }
}
.ami-pending {
  &:before {
    content: $ami-pending; 
  }
}
.ami-play-circle {
  &:before {
    content: $ami-play-circle; 
  }
}
.ami-stop-circle {
  &:before {
    content: $ami-stop-circle; 
  }
}
.ami-tick-circle {
  &:before {
    content: $ami-tick-circle; 
  }
}
.ami-alert-circle {
  &:before {
    content: $ami-alert-circle; 
  }
}
.ami-breeder-configuration {
  &:before {
    content: $ami-breeder-configuration; 
  }
}
.ami-breeder-dashboard {
  &:before {
    content: $ami-breeder-dashboard; 
  }
}
.ami-breeder-flocks {
  &:before {
    content: $ami-breeder-flocks; 
  }
}
.ami-breeder-log-files {
  &:before {
    content: $ami-breeder-log-files; 
  }
}
.ami-breeder-profiles {
  &:before {
    content: $ami-breeder-profiles; 
  }
}
.ami-growout-configuration {
  &:before {
    content: $ami-growout-configuration; 
  }
}
.ami-growout-dashboard {
  &:before {
    content: $ami-growout-dashboard; 
  }
}
.ami-growout-log-files {
  &:before {
    content: $ami-growout-log-files; 
  }
}
.ami-growout-placement-projection {
  &:before {
    content: $ami-growout-placement-projection; 
  }
}
.ami-growout-plant-projection {
  &:before {
    content: $ami-growout-plant-projection; 
  }
}
.ami-growout-profiles {
  &:before {
    content: $ami-growout-profiles; 
  }
}
.ami-hatchery-chick-distribution {
  &:before {
    content: $ami-hatchery-chick-distribution; 
  }
}
.ami-hatchery-configurations {
  &:before {
    content: $ami-hatchery-configurations; 
  }
}
.ami-hatchery-egg-distribution {
  &:before {
    content: $ami-hatchery-egg-distribution; 
  }
}
.ami-hatchery-log-files {
  &:before {
    content: $ami-hatchery-log-files; 
  }
}
.ami-hatchery-product {
  &:before {
    content: $ami-hatchery-product; 
  }
}
.ami-hatchery-profiles {
  &:before {
    content: $ami-hatchery-profiles; 
  }
}
.ami-live-haul-configuration {
  &:before {
    content: $ami-live-haul-configuration; 
  }
}
.ami-live-haul-dashboard {
  &:before {
    content: $ami-live-haul-dashboard; 
  }
}
.ami-live-haul-log-files {
  &:before {
    content: $ami-live-haul-log-files; 
  }
}
.ami-live-haul-profiles {
  &:before {
    content: $ami-live-haul-profiles; 
  }
}
.ami-live-haul-projections {
  &:before {
    content: $ami-live-haul-projections; 
  }
}
.ami-live-haul-resources {
  &:before {
    content: $ami-live-haul-resources; 
  }
}
.ami-groups {
  &:before {
    content: $ami-groups; 
  }
}
.ami-summaries {
  &:before {
    content: $ami-summaries; 
  }
}
.ami-screen-profiles {
  &:before {
    content: $ami-screen-profiles; 
  }
}
.ami-supply-chain-planning {
  &:before {
    content: $ami-supply-chain-planning; 
  }
}
.ami-disabled-filters {
  &:before {
    content: $ami-disabled-filters; 
  }
}
.ami-finisher-pigs_1 {
  &:before {
    content: $ami-finisher-pigs_1; 
  }
}
.ami-finisher-pigs {
  &:before {
    content: $ami-finisher-pigs; 
  }
}
.ami-nursery-pig {
  &:before {
    content: $ami-nursery-pig; 
  }
}
.ami-nursery-pigs {
  &:before {
    content: $ami-nursery-pigs; 
  }
}
.ami-weaned-pig {
  &:before {
    content: $ami-weaned-pig; 
  }
}
.ami-weaned-pigs {
  &:before {
    content: $ami-weaned-pigs; 
  }
}
.ami-egg-distribution {
  &:before {
    content: $ami-egg-distribution; 
  }
}
.ami-chick-distribution {
  &:before {
    content: $ami-chick-distribution; 
  }
}
.ami-notes {
  &:before {
    content: $ami-notes; 
  }
}
.ami-data-base {
  &:before {
    content: $ami-data-base; 
  }
}
.ami-tree-component {
  &:before {
    content: $ami-tree-component; 
  }
}
.ami-dropdown {
  &:before {
    content: $ami-dropdown; 
  }
}
.ami-volume {
  &:before {
    content: $ami-volume; 
  }
}
.ami-add-circle {
  &:before {
    content: $ami-add-circle; 
  }
}
.ami-flag {
  &:before {
    content: $ami-flag; 
  }
}
.ami-folder-open {
  &:before {
    content: $ami-folder-open; 
  }
}
.ami-method {
  &:before {
    content: $ami-method; 
  }
}
.ami-property {
  &:before {
    content: $ami-property; 
  }
}
.ami-clear-projections {
  &:before {
    content: $ami-clear-projections; 
  }
}
.ami-report-layouts {
  &:before {
    content: $ami-report-layouts; 
  }
}
.ami-sustain-emissions-calculator {
  &:before {
    content: $ami-sustain-emissions-calculator; 
  }
}
.ami-cv {
  &:before {
    content: $ami-cv; 
  }
}
.ami-feed-conversion {
  &:before {
    content: $ami-feed-conversion; 
  }
}
.ami-uniformity {
  &:before {
    content: $ami-uniformity; 
  }
}
.ami-sustain-electricity {
  &:before {
    content: $ami-sustain-electricity; 
  }
}
.ami-sustain-feed {
  &:before {
    content: $ami-sustain-feed; 
  }
}
.ami-sustain-fuel {
  &:before {
    content: $ami-sustain-fuel; 
  }
}
.ami-sustain-main {
  &:before {
    content: $ami-sustain-main; 
  }
}
.ami-sustain-manure {
  &:before {
    content: $ami-sustain-manure; 
  }
}
.ami-sustain-refrigeration {
  &:before {
    content: $ami-sustain-refrigeration; 
  }
}
.ami-sustain-steam {
  &:before {
    content: $ami-sustain-steam; 
  }
}
.ami-sustain-waste {
  &:before {
    content: $ami-sustain-waste; 
  }
}
.ami-sustain-water {
  &:before {
    content: $ami-sustain-water; 
  }
}
.ami-restore-master-data-link {
  &:before {
    content: $ami-restore-master-data-link; 
  }
}
.ami-herd-events {
  &:before {
    content: $ami-herd-events; 
  }
}
.ami-json {
  &:before {
    content: $ami-json; 
  }
}
.ami-run-optimizer {
  &:before {
    content: $ami-run-optimizer; 
  }
}
.ami-search-herd-events {
  &:before {
    content: $ami-search-herd-events; 
  }
}
.ami-semen {
  &:before {
    content: $ami-semen; 
  }
}
.ami-capitalizations-swine {
  &:before {
    content: $ami-capitalizations-swine; 
  }
}
.ami-light-intensity {
  &:before {
    content: $ami-light-intensity; 
  }
}
.ami-static-pressure {
  &:before {
    content: $ami-static-pressure; 
  }
}
.ami-long-term-targets {
  &:before {
    content: $ami-long-term-targets; 
  }
}
.ami-machinery-efficiency-highest {
  &:before {
    content: $ami-machinery-efficiency-highest; 
  }
}
.ami-machinery-efficiency-lowest {
  &:before {
    content: $ami-machinery-efficiency-lowest; 
  }
}
.ami-machinery-efficiency {
  &:before {
    content: $ami-machinery-efficiency; 
  }
}
.ami-overall-yield {
  &:before {
    content: $ami-overall-yield; 
  }
}
.ami-storage-conversions {
  &:before {
    content: $ami-storage-conversions; 
  }
}
.ami-storage-costs {
  &:before {
    content: $ami-storage-costs; 
  }
}
.ami-on-time-in-full {
  &:before {
    content: $ami-on-time-in-full; 
  }
}
.ami-share-dashboards {
  &:before {
    content: $ami-share-dashboards; 
  }
}
.ami-stock-out {
  &:before {
    content: $ami-stock-out; 
  }
}
.ami-post-new {
  &:before {
    content: $ami-post-new; 
  }
}
.ami-post {
  &:before {
    content: $ami-post; 
  }
}
.ami-save-new {
  &:before {
    content: $ami-save-new; 
  }
}
.ami-resources-utilization {
  &:before {
    content: $ami-resources-utilization; 
  }
}
.ami-indirect-labor {
  &:before {
    content: $ami-indirect-labor; 
  }
}
.ami-demand {
  &:before {
    content: $ami-demand; 
  }
}
.ami-off {
  &:before {
    content: $ami-off; 
  }
}
.ami-offline {
  &:before {
    content: $ami-offline; 
  }
}
.ami-on {
  &:before {
    content: $ami-on; 
  }
}
.ami-print {
  &:before {
    content: $ami-print; 
  }
}
.ami-wizard {
  &:before {
    content: $ami-wizard; 
  }
}
.ami-bottlenecks {
  &:before {
    content: $ami-bottlenecks; 
  }
}
.ami-format-align-center {
  &:before {
    content: $ami-format-align-center; 
  }
}
.ami-format-align-left {
  &:before {
    content: $ami-format-align-left; 
  }
}
.ami-format-align-right {
  &:before {
    content: $ami-format-align-right; 
  }
}
.ami-record {
  &:before {
    content: $ami-record; 
  }
}
.ami-financial {
  &:before {
    content: $ami-financial; 
  }
}
.ami-purchases {
  &:before {
    content: $ami-purchases; 
  }
}
.ami-sales {
  &:before {
    content: $ami-sales; 
  }
}
.ami-result-analysis {
  &:before {
    content: $ami-result-analysis; 
  }
}
.ami-list {
  &:before {
    content: $ami-list; 
  }
}
.ami-plant-assignment {
  &:before {
    content: $ami-plant-assignment; 
  }
}
.ami-layers {
  &:before {
    content: $ami-layers; 
  }
}
.ami-creating-loads {
  &:before {
    content: $ami-creating-loads; 
  }
}
.ami-haul-back {
  &:before {
    content: $ami-haul-back; 
  }
}
.ami-w-cloudy {
  &:before {
    content: $ami-w-cloudy; 
  }
}
.ami-w-fog {
  &:before {
    content: $ami-w-fog; 
  }
}
.ami-w-heat {
  &:before {
    content: $ami-w-heat; 
  }
}
.ami-w-humidity {
  &:before {
    content: $ami-w-humidity; 
  }
}
.ami-w-lightning {
  &:before {
    content: $ami-w-lightning; 
  }
}
.ami-w-main {
  &:before {
    content: $ami-w-main; 
  }
}
.ami-w-night {
  &:before {
    content: $ami-w-night; 
  }
}
.ami-w-partlycloudy {
  &:before {
    content: $ami-w-partlycloudy; 
  }
}
.ami-w-precipitation {
  &:before {
    content: $ami-w-precipitation; 
  }
}
.ami-w-rainy {
  &:before {
    content: $ami-w-rainy; 
  }
}
.ami-w-snow {
  &:before {
    content: $ami-w-snow; 
  }
}
.ami-w-sunny {
  &:before {
    content: $ami-w-sunny; 
  }
}
.ami-w-windy {
  &:before {
    content: $ami-w-windy; 
  }
}
.ami-gas-consumption {
  &:before {
    content: $ami-gas-consumption; 
  }
}
.ami-power-consumption {
  &:before {
    content: $ami-power-consumption; 
  }
}
.ami-air-flow {
  &:before {
    content: $ami-air-flow; 
  }
}
.ami-ammonia {
  &:before {
    content: $ami-ammonia; 
  }
}
.ami-carbon-dioxide {
  &:before {
    content: $ami-carbon-dioxide; 
  }
}
.ami-humidity {
  &:before {
    content: $ami-humidity; 
  }
}
.ami-sensors {
  &:before {
    content: $ami-sensors; 
  }
}
.ami-sonar {
  &:before {
    content: $ami-sonar; 
  }
}
.ami-threshold {
  &:before {
    content: $ami-threshold; 
  }
}
.ami-water {
  &:before {
    content: $ami-water; 
  }
}
.ami-bell-curve {
  &:before {
    content: $ami-bell-curve; 
  }
}
.ami-condemnations_1 {
  &:before {
    content: $ami-condemnations_1; 
  }
}
.ami-doa-chicken {
  &:before {
    content: $ami-doa-chicken; 
  }
}
.ami-doa-duck {
  &:before {
    content: $ami-doa-duck; 
  }
}
.ami-doa-swine {
  &:before {
    content: $ami-doa-swine; 
  }
}
.ami-doa-turkey {
  &:before {
    content: $ami-doa-turkey; 
  }
}
.ami-long-term {
  &:before {
    content: $ami-long-term; 
  }
}
.ami-materials {
  &:before {
    content: $ami-materials; 
  }
}
.ami-processing-duck {
  &:before {
    content: $ami-processing-duck; 
  }
}
.ami-processing-orders {
  &:before {
    content: $ami-processing-orders; 
  }
}
.ami-processing-swine {
  &:before {
    content: $ami-processing-swine; 
  }
}
.ami-processing-turkey {
  &:before {
    content: $ami-processing-turkey; 
  }
}
.ami-product-breakdown {
  &:before {
    content: $ami-product-breakdown; 
  }
}
.ami-pull-mode {
  &:before {
    content: $ami-pull-mode; 
  }
}
.ami-replanning-alt {
  &:before {
    content: $ami-replanning-alt; 
  }
}
.ami-replanning {
  &:before {
    content: $ami-replanning; 
  }
}
.ami-short-term {
  &:before {
    content: $ami-short-term; 
  }
}
.ami-shrink {
  &:before {
    content: $ami-shrink; 
  }
}
.ami-source-animals {
  &:before {
    content: $ami-source-animals; 
  }
}
.ami-supply {
  &:before {
    content: $ami-supply; 
  }
}
.ami-target {
  &:before {
    content: $ami-target; 
  }
}
.ami-unassigned {
  &:before {
    content: $ami-unassigned; 
  }
}
.ami-activity {
  &:before {
    content: $ami-activity; 
  }
}
.ami-analytical-structures {
  &:before {
    content: $ami-analytical-structures; 
  }
}
.ami-bin {
  &:before {
    content: $ami-bin; 
  }
}
.ami-bonus {
  &:before {
    content: $ami-bonus; 
  }
}
.ami-breeder-placement {
  &:before {
    content: $ami-breeder-placement; 
  }
}
.ami-breeder {
  &:before {
    content: $ami-breeder; 
  }
}
.ami-calc-pay {
  &:before {
    content: $ami-calc-pay; 
  }
}
.ami-capitalizations {
  &:before {
    content: $ami-capitalizations; 
  }
}
.ami-chick-dispose {
  &:before {
    content: $ami-chick-dispose; 
  }
}
.ami-chick {
  &:before {
    content: $ami-chick; 
  }
}
.ami-chicken {
  &:before {
    content: $ami-chicken; 
  }
}
.ami-compositions {
  &:before {
    content: $ami-compositions; 
  }
}
.ami-condemnations {
  &:before {
    content: $ami-condemnations; 
  }
}
.ami-cost-details {
  &:before {
    content: $ami-cost-details; 
  }
}
.ami-ducks {
  &:before {
    content: $ami-ducks; 
  }
}
.ami-egg-dispose {
  &:before {
    content: $ami-egg-dispose; 
  }
}
.ami-egg-regrade {
  &:before {
    content: $ami-egg-regrade; 
  }
}
.ami-egg-residue {
  &:before {
    content: $ami-egg-residue; 
  }
}
.ami-egg-room {
  &:before {
    content: $ami-egg-room; 
  }
}
.ami-egg-sales {
  &:before {
    content: $ami-egg-sales; 
  }
}
.ami-egg-transfer {
  &:before {
    content: $ami-egg-transfer; 
  }
}
.ami-eggs {
  &:before {
    content: $ami-eggs; 
  }
}
.ami-enviromental {
  &:before {
    content: $ami-enviromental; 
  }
}
.ami-excel {
  &:before {
    content: $ami-excel; 
  }
}
.ami-farm {
  &:before {
    content: $ami-farm; 
  }
}
.ami-feed-consumed {
  &:before {
    content: $ami-feed-consumed; 
  }
}
.ami-feed-delivery {
  &:before {
    content: $ami-feed-delivery; 
  }
}
.ami-feed-inventories {
  &:before {
    content: $ami-feed-inventories; 
  }
}
.ami-feed {
  &:before {
    content: $ami-feed; 
  }
}
.ami-field-products {
  &:before {
    content: $ami-field-products; 
  }
}
.ami-field {
  &:before {
    content: $ami-field; 
  }
}
.ami-fm-main {
  &:before {
    content: $ami-fm-main; 
  }
}
.ami-growout-placement {
  &:before {
    content: $ami-growout-placement; 
  }
}
.ami-growout {
  &:before {
    content: $ami-growout; 
  }
}
.ami-hallway {
  &:before {
    content: $ami-hallway; 
  }
}
.ami-hatcher {
  &:before {
    content: $ami-hatcher; 
  }
}
.ami-health {
  &:before {
    content: $ami-health; 
  }
}
.ami-home {
  &:before {
    content: $ami-home; 
  }
}
.ami-house {
  &:before {
    content: $ami-house; 
  }
}
.ami-houses {
  &:before {
    content: $ami-houses; 
  }
}
.ami-interface {
  &:before {
    content: $ami-interface; 
  }
}
.ami-laboratories {
  &:before {
    content: $ami-laboratories; 
  }
}
.ami-leftovers {
  &:before {
    content: $ami-leftovers; 
  }
}
.ami-money {
  &:before {
    content: $ami-money; 
  }
}
.ami-mortality {
  &:before {
    content: $ami-mortality; 
  }
}
.ami-mtech-orb {
  &:before {
    content: $ami-mtech-orb; 
  }
}
.ami-po-main {
  &:before {
    content: $ami-po-main; 
  }
}
.ami-processing {
  &:before {
    content: $ami-processing; 
  }
}
.ami-product-line {
  &:before {
    content: $ami-product-line; 
  }
}
.ami-product-order {
  &:before {
    content: $ami-product-order; 
  }
}
.ami-projection-load {
  &:before {
    content: $ami-projection-load; 
  }
}
.ami-projection {
  &:before {
    content: $ami-projection; 
  }
}
.ami-setter {
  &:before {
    content: $ami-setter; 
  }
}
.ami-standards {
  &:before {
    content: $ami-standards; 
  }
}
.ami-surveys {
  &:before {
    content: $ami-surveys; 
  }
}
.ami-swine {
  &:before {
    content: $ami-swine; 
  }
}
.ami-temperature {
  &:before {
    content: $ami-temperature; 
  }
}
.ami-transfer-alt {
  &:before {
    content: $ami-transfer-alt; 
  }
}
.ami-transfer {
  &:before {
    content: $ami-transfer; 
  }
}
.ami-turkey {
  &:before {
    content: $ami-turkey; 
  }
}
.ami-wastes {
  &:before {
    content: $ami-wastes; 
  }
}
.ami-weight {
  &:before {
    content: $ami-weight; 
  }
}
.ami-wh-main {
  &:before {
    content: $ami-wh-main; 
  }
}
.ami-chevron-left {
  &:before {
    content: $ami-chevron-left; 
  }
}
.ami-chevron-right {
  &:before {
    content: $ami-chevron-right; 
  }
}
.ami-chevron-down {
  &:before {
    content: $ami-chevron-down; 
  }
}
.ami-chevron-up {
  &:before {
    content: $ami-chevron-up; 
  }
}
.ami-slim-left {
  &:before {
    content: $ami-slim-left; 
  }
}
.ami-slim-up {
  &:before {
    content: $ami-slim-up; 
  }
}
.ami-slim-right {
  &:before {
    content: $ami-slim-right; 
  }
}
.ami-slim-down {
  &:before {
    content: $ami-slim-down; 
  }
}
.ami-arrow-up {
  &:before {
    content: $ami-arrow-up; 
  }
}
.ami-arrow-right {
  &:before {
    content: $ami-arrow-right; 
  }
}
.ami-arrow-down {
  &:before {
    content: $ami-arrow-down; 
  }
}
.ami-arrow-left {
  &:before {
    content: $ami-arrow-left; 
  }
}
.ami-slim-backward {
  &:before {
    content: $ami-slim-backward; 
  }
}
.ami-slim-upward {
  &:before {
    content: $ami-slim-upward; 
  }
}
.ami-slim-forward {
  &:before {
    content: $ami-slim-forward; 
  }
}
.ami-slim-downward {
  &:before {
    content: $ami-slim-downward; 
  }
}
.ami-chevron-forward {
  &:before {
    content: $ami-chevron-forward; 
  }
}
.ami-chevron-upward {
  &:before {
    content: $ami-chevron-upward; 
  }
}
.ami-chevron-backward {
  &:before {
    content: $ami-chevron-backward; 
  }
}
.ami-chevron-downward {
  &:before {
    content: $ami-chevron-downward; 
  }
}
.ami-forward {
  &:before {
    content: $ami-forward; 
  }
}
.ami-backward {
  &:before {
    content: $ami-backward; 
  }
}
.ami-drop-down {
  &:before {
    content: $ami-drop-down; 
  }
}
.ami-drop-right {
  &:before {
    content: $ami-drop-right; 
  }
}
.ami-drop-up {
  &:before {
    content: $ami-drop-up; 
  }
}
.ami-drop-left {
  &:before {
    content: $ami-drop-left; 
  }
}
.ami-drop-up-right {
  &:before {
    content: $ami-drop-up-right; 
  }
}
.ami-drop-down-left {
  &:before {
    content: $ami-drop-down-left; 
  }
}
.ami-drop-up-left {
  &:before {
    content: $ami-drop-up-left; 
  }
}
.ami-drop-down-right {
  &:before {
    content: $ami-drop-down-right; 
  }
}
.ami-age {
  &:before {
    content: $ami-age; 
  }
}
.ami-alert {
  &:before {
    content: $ami-alert; 
  }
}
.ami-analytics {
  &:before {
    content: $ami-analytics; 
  }
}
.ami-bell-alt {
  &:before {
    content: $ami-bell-alt; 
  }
}
.ami-bell {
  &:before {
    content: $ami-bell; 
  }
}
.ami-big-cards {
  &:before {
    content: $ami-big-cards; 
  }
}
.ami-building {
  &:before {
    content: $ami-building; 
  }
}
.ami-calendar {
  &:before {
    content: $ami-calendar; 
  }
}
.ami-cancel {
  &:before {
    content: $ami-cancel; 
  }
}
.ami-cart {
  &:before {
    content: $ami-cart; 
  }
}
.ami-chart-bar {
  &:before {
    content: $ami-chart-bar; 
  }
}
.ami-chart-donut {
  &:before {
    content: $ami-chart-donut; 
  }
}
.ami-chart-line {
  &:before {
    content: $ami-chart-line; 
  }
}
.ami-clone {
  &:before {
    content: $ami-clone; 
  }
}
.ami-collapse {
  &:before {
    content: $ami-collapse; 
  }
}
.ami-copy-daily {
  &:before {
    content: $ami-copy-daily; 
  }
}
.ami-copy-date {
  &:before {
    content: $ami-copy-date; 
  }
}
.ami-currency {
  &:before {
    content: $ami-currency; 
  }
}
.ami-dashboard {
  &:before {
    content: $ami-dashboard; 
  }
}
.ami-delete {
  &:before {
    content: $ami-delete; 
  }
}
.ami-disk {
  &:before {
    content: $ami-disk; 
  }
}
.ami-download {
  &:before {
    content: $ami-download; 
  }
}
.ami-drag-horizontal {
  &:before {
    content: $ami-drag-horizontal; 
  }
}
.ami-drag-vertical {
  &:before {
    content: $ami-drag-vertical; 
  }
}
.ami-ellipsis {
  &:before {
    content: $ami-ellipsis; 
  }
}
.ami-excel1 {
  &:before {
    content: $ami-excel1; 
  }
}
.ami-expand {
  &:before {
    content: $ami-expand; 
  }
}
.ami-favorrite {
  &:before {
    content: $ami-favorrite; 
  }
}
.ami-file-pdf {
  &:before {
    content: $ami-file-pdf; 
  }
}
.ami-file-xls {
  &:before {
    content: $ami-file-xls; 
  }
}
.ami-file {
  &:before {
    content: $ami-file; 
  }
}
.ami-filter-alt-clear {
  &:before {
    content: $ami-filter-alt-clear; 
  }
}
.ami-filter-alt {
  &:before {
    content: $ami-filter-alt; 
  }
}
.ami-filter-clear {
  &:before {
    content: $ami-filter-clear; 
  }
}
.ami-filter {
  &:before {
    content: $ami-filter; 
  }
}
.ami-finance {
  &:before {
    content: $ami-finance; 
  }
}
.ami-flow-chart-link {
  &:before {
    content: $ami-flow-chart-link; 
  }
}
.ami-flow-chart-unlink {
  &:before {
    content: $ami-flow-chart-unlink; 
  }
}
.ami-flow-data {
  &:before {
    content: $ami-flow-data; 
  }
}
.ami-folder {
  &:before {
    content: $ami-folder; 
  }
}
.ami-gantt-alt {
  &:before {
    content: $ami-gantt-alt; 
  }
}
.ami-gantt {
  &:before {
    content: $ami-gantt; 
  }
}
.ami-gear {
  &:before {
    content: $ami-gear; 
  }
}
.ami-gears {
  &:before {
    content: $ami-gears; 
  }
}
.ami-group {
  &:before {
    content: $ami-group; 
  }
}
.ami-hamburger {
  &:before {
    content: $ami-hamburger; 
  }
}
.ami-help {
  &:before {
    content: $ami-help; 
  }
}
.ami-hide {
  &:before {
    content: $ami-hide; 
  }
}
.ami-history {
  &:before {
    content: $ami-history; 
  }
}
.ami-home1 {
  &:before {
    content: $ami-home1; 
  }
}
.ami-image {
  &:before {
    content: $ami-image; 
  }
}
.ami-indent {
  &:before {
    content: $ami-indent; 
  }
}
.ami-info-alt {
  &:before {
    content: $ami-info-alt; 
  }
}
.ami-info {
  &:before {
    content: $ami-info; 
  }
}
.ami-key {
  &:before {
    content: $ami-key; 
  }
}
.ami-language {
  &:before {
    content: $ami-language; 
  }
}
.ami-lightning {
  &:before {
    content: $ami-lightning; 
  }
}
.ami-lock {
  &:before {
    content: $ami-lock; 
  }
}
.ami-log-off {
  &:before {
    content: $ami-log-off; 
  }
}
.ami-machine-learning {
  &:before {
    content: $ami-machine-learning; 
  }
}
.ami-machinery {
  &:before {
    content: $ami-machinery; 
  }
}
.ami-map {
  &:before {
    content: $ami-map; 
  }
}
.ami-master-data {
  &:before {
    content: $ami-master-data; 
  }
}
.ami-minus {
  &:before {
    content: $ami-minus; 
  }
}
.ami-more {
  &:before {
    content: $ami-more; 
  }
}
.ami-new-folder {
  &:before {
    content: $ami-new-folder; 
  }
}
.ami-new-page {
  &:before {
    content: $ami-new-page; 
  }
}
.ami-news {
  &:before {
    content: $ami-news; 
  }
}
.ami-not-reviewed {
  &:before {
    content: $ami-not-reviewed; 
  }
}
.ami-open {
  &:before {
    content: $ami-open; 
  }
}
.ami-outdent {
  &:before {
    content: $ami-outdent; 
  }
}
.ami-page {
  &:before {
    content: $ami-page; 
  }
}
.ami-palette {
  &:before {
    content: $ami-palette; 
  }
}
.ami-pencil {
  &:before {
    content: $ami-pencil; 
  }
}
.ami-people {
  &:before {
    content: $ami-people; 
  }
}
.ami-phone-rotate-landscape {
  &:before {
    content: $ami-phone-rotate-landscape; 
  }
}
.ami-phone-rotate-portrait {
  &:before {
    content: $ami-phone-rotate-portrait; 
  }
}
.ami-pin {
  &:before {
    content: $ami-pin; 
  }
}
.ami-pinned {
  &:before {
    content: $ami-pinned; 
  }
}
.ami-pivot-view {
  &:before {
    content: $ami-pivot-view; 
  }
}
.ami-play {
  &:before {
    content: $ami-play; 
  }
}
.ami-plus {
  &:before {
    content: $ami-plus; 
  }
}
.ami-power {
  &:before {
    content: $ami-power; 
  }
}
.ami-products {
  &:before {
    content: $ami-products; 
  }
}
.ami-refresh {
  &:before {
    content: $ami-refresh; 
  }
}
.ami-reorder-horizontal {
  &:before {
    content: $ami-reorder-horizontal; 
  }
}
.ami-reorder-vertical {
  &:before {
    content: $ami-reorder-vertical; 
  }
}
.ami-report-builder {
  &:before {
    content: $ami-report-builder; 
  }
}
.ami-reports {
  &:before {
    content: $ami-reports; 
  }
}
.ami-return {
  &:before {
    content: $ami-return; 
  }
}
.ami-reviewed {
  &:before {
    content: $ami-reviewed; 
  }
}
.ami-rocket {
  &:before {
    content: $ami-rocket; 
  }
}
.ami-rss {
  &:before {
    content: $ami-rss; 
  }
}
.ami-ruler {
  &:before {
    content: $ami-ruler; 
  }
}
.ami-schedule {
  &:before {
    content: $ami-schedule; 
  }
}
.ami-search {
  &:before {
    content: $ami-search; 
  }
}
.ami-shrink1 {
  &:before {
    content: $ami-shrink1; 
  }
}
.ami-small-cards {
  &:before {
    content: $ami-small-cards; 
  }
}
.ami-sort-alpha-a {
  &:before {
    content: $ami-sort-alpha-a; 
  }
}
.ami-sort-alpha-d {
  &:before {
    content: $ami-sort-alpha-d; 
  }
}
.ami-sort-numeric-a {
  &:before {
    content: $ami-sort-numeric-a; 
  }
}
.ami-sort-numeric-d {
  &:before {
    content: $ami-sort-numeric-d; 
  }
}
.ami-source-control {
  &:before {
    content: $ami-source-control; 
  }
}
.ami-speed {
  &:before {
    content: $ami-speed; 
  }
}
.ami-success {
  &:before {
    content: $ami-success; 
  }
}
.ami-support {
  &:before {
    content: $ami-support; 
  }
}
.ami-table-large {
  &:before {
    content: $ami-table-large; 
  }
}
.ami-tasks {
  &:before {
    content: $ami-tasks; 
  }
}
.ami-tiles {
  &:before {
    content: $ami-tiles; 
  }
}
.ami-time {
  &:before {
    content: $ami-time; 
  }
}
.ami-tools {
  &:before {
    content: $ami-tools; 
  }
}
.ami-uncollapse {
  &:before {
    content: $ami-uncollapse; 
  }
}
.ami-ungroup {
  &:before {
    content: $ami-ungroup; 
  }
}
.ami-upload {
  &:before {
    content: $ami-upload; 
  }
}
.ami-user-role {
  &:before {
    content: $ami-user-role; 
  }
}
.ami-user {
  &:before {
    content: $ami-user; 
  }
}
.ami-view {
  &:before {
    content: $ami-view; 
  }
}
.ami-walk {
  &:before {
    content: $ami-walk; 
  }
}
.ami-warning {
  &:before {
    content: $ami-warning; 
  }
}
.ami-windows {
  &:before {
    content: $ami-windows; 
  }
}
.ami-worker {
  &:before {
    content: $ami-worker; 
  }
}
.ami-zoom-center {
  &:before {
    content: $ami-zoom-center; 
  }
}
.ami-zoom-in {
  &:before {
    content: $ami-zoom-in; 
  }
}
.ami-zoom-out {
  &:before {
    content: $ami-zoom-out; 
  }
}
