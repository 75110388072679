@font-face {
  font-family: "sonar";
  src: url("./sonar.eot?nworbs");
  src: url("./sonar.eot?nworbs#iefix") format("embedded-opentype"),
    url("./sonar.ttf?nworbs") format("truetype"),
    url("./sonar.woff?nworbs") format("woff"),
    url("./sonar.svg?nworbs#sonar") format("svg");
  font-weight: normal;
  font-style: normal;
}

.snr {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "sonar" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.snr-alert:before {
  content: "\e900";
}
.snr-ammonia:before {
  content: "\e901";
}
.snr-farms:before {
  content: "\e902";
}
.snr-feed:before {
  content: "\e903";
}
.snr-humidity:before {
  content: "\e904";
}
.snr-mortality:before {
  content: "\e905";
}
.snr-refresh:before {
  content: "\e906";
}
.snr-sensor:before {
  content: "\e907";
}
.snr-settings:before {
  content: "\e908";
}
.snr-sonar:before {
  content: "\e909";
}
.snr-temperature:before {
  content: "\e90a";
}
.snr-water:before {
  content: "\e90b";
}
.snr-weight:before {
  content: "\e90c";
}
