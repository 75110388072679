@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500");

@font-face {
  font-family: "FiraSans";
  src: url("./FiraSans-Light.woff") format("woff"),
    url("./FiraSans-Light.ttf") format("truetype"),
    url("./FiraSans-Light.svg#fira_sanslight") format("svg");
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "FiraSans";
  src: url("./FiraSans-Regular.woff") format("woff"),
    url("./FiraSans-Regular.ttf") format("truetype"),
    url("./FiraSans-Regular.svg#FiraSans") format("svg");
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "FiraSans";
  src: url("./FiraSans-Medium.woff") format("woff"),
    url("./FiraSans-Medium.ttf") format("truetype"),
    url("./FiraSans-Medium.svg#FiraSans") format("svg");
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
