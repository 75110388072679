@use "@angular/material" as mat;

/* Angular Material */
@include mat.core();

$light-primary-text: var(--hx-normal--dark);
$dark-primary-text: var(--hx-normal);

$mat-primary: (
  main: var(--hx-prim-500),
  lighter: var(--hx-prim-50),
  darker: var(--hx-prim-900),
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: var(--hx-accent-400),
  lighter: var(--hx-accent-100),
  darker: var(--hx-accent-700),
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
  main: var(--hx-alert-400),
  lighter: var(--hx-alert-100),
  darker: var(--hx-alert-700),
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);

@include mat.all-component-themes($theme);

@import "variables-sonar.scss";
