$ami-download-text: "\ea92";
$ami-open-right: "\ea93";
$ami-attachments: "\ea8d";
$ami-collapse-panel: "\ea8e";
$ami-custom: "\ea8f";
$ami-expand-panel: "\ea90";
$ami-my-workspace: "\ea91";
$ami-swine-scp: "\ea8c";
$ami-background-jobs: "\ea86";
$ami-swine-configuration: "\ea87";
$ami-swine-dashboard: "\ea88";
$ami-swine-log-file: "\ea89";
$ami-swine-placement-projection: "\ea8a";
$ami-swine-profile: "\ea8b";
$ami-interface-batches: "\ea81";
$ami-report-batches: "\ea82";
$ami-scan-barcode: "\ea83";
$ami-scan-qr: "\ea84";
$ami-scan-wand-tool: "\ea85";
$ami-volume-off: "\ea46";
$ami-layer-configuration: "\ea79";
$ami-layer-dashboard: "\ea7a";
$ami-layer-flocks: "\ea7b";
$ami-layer-log-file: "\ea7c";
$ami-layer-profiles: "\ea7d";
$ami-animal-welfare-index: "\ea7e";
$ami-distress: "\ea7f";
$ami-movement: "\ea80";
$ami-breed-wean-right: "\ea77";
$ami-breed-wean-left: "\ea78";
$ami-check: "\ea70";
$ami-delete-all: "\ea71";
$ami-pending: "\ea72";
$ami-play-circle: "\ea73";
$ami-stop-circle: "\ea74";
$ami-tick-circle: "\ea75";
$ami-alert-circle: "\ea76";
$ami-breeder-configuration: "\ea57";
$ami-breeder-dashboard: "\ea58";
$ami-breeder-flocks: "\ea59";
$ami-breeder-log-files: "\ea5a";
$ami-breeder-profiles: "\ea5b";
$ami-growout-configuration: "\ea5c";
$ami-growout-dashboard: "\ea5d";
$ami-growout-log-files: "\ea5e";
$ami-growout-placement-projection: "\ea5f";
$ami-growout-plant-projection: "\ea60";
$ami-growout-profiles: "\ea63";
$ami-hatchery-chick-distribution: "\ea64";
$ami-hatchery-configurations: "\ea65";
$ami-hatchery-egg-distribution: "\ea66";
$ami-hatchery-log-files: "\ea67";
$ami-hatchery-product: "\ea68";
$ami-hatchery-profiles: "\ea69";
$ami-live-haul-configuration: "\ea6a";
$ami-live-haul-dashboard: "\ea6b";
$ami-live-haul-log-files: "\ea6c";
$ami-live-haul-profiles: "\ea6d";
$ami-live-haul-projections: "\ea6e";
$ami-live-haul-resources: "\ea6f";
$ami-groups: "\ea56";
$ami-summaries: "\ea62";
$ami-screen-profiles: "\ea61";
$ami-supply-chain-planning: "\ea55";
$ami-disabled-filters: "\ea4e";
$ami-finisher-pigs_1: "\ea4f";
$ami-finisher-pigs: "\ea50";
$ami-nursery-pig: "\ea51";
$ami-nursery-pigs: "\ea52";
$ami-weaned-pig: "\ea53";
$ami-weaned-pigs: "\ea54";
$ami-egg-distribution: "\ea4c";
$ami-chick-distribution: "\ea4d";
$ami-notes: "\ea4b";
$ami-data-base: "\ea4a";
$ami-tree-component: "\ea48";
$ami-dropdown: "\ea49";
$ami-volume: "\ea47";
$ami-add-circle: "\ea41";
$ami-flag: "\ea42";
$ami-folder-open: "\ea43";
$ami-method: "\ea44";
$ami-property: "\ea45";
$ami-clear-projections: "\ea3f";
$ami-report-layouts: "\ea40";
$ami-sustain-emissions-calculator: "\ea3e";
$ami-cv: "\ea3b";
$ami-feed-conversion: "\ea3c";
$ami-uniformity: "\ea3d";
$ami-sustain-electricity: "\ea32";
$ami-sustain-feed: "\ea33";
$ami-sustain-fuel: "\ea34";
$ami-sustain-main: "\ea35";
$ami-sustain-manure: "\ea36";
$ami-sustain-refrigeration: "\ea37";
$ami-sustain-steam: "\ea38";
$ami-sustain-waste: "\ea39";
$ami-sustain-water: "\ea3a";
$ami-restore-master-data-link: "\ea31";
$ami-herd-events: "\ea2c";
$ami-json: "\ea2d";
$ami-run-optimizer: "\ea2e";
$ami-search-herd-events: "\ea2f";
$ami-semen: "\ea30";
$ami-capitalizations-swine: "\ea2b";
$ami-light-intensity: "\ea29";
$ami-static-pressure: "\ea2a";
$ami-long-term-targets: "\ea22";
$ami-machinery-efficiency-highest: "\ea23";
$ami-machinery-efficiency-lowest: "\ea24";
$ami-machinery-efficiency: "\ea25";
$ami-overall-yield: "\ea26";
$ami-storage-conversions: "\ea27";
$ami-storage-costs: "\ea28";
$ami-on-time-in-full: "\ea1f";
$ami-share-dashboards: "\ea20";
$ami-stock-out: "\ea21";
$ami-post-new: "\ea1c";
$ami-post: "\ea1d";
$ami-save-new: "\ea1e";
$ami-resources-utilization: "\ea1a";
$ami-indirect-labor: "\ea1b";
$ami-demand: "\ea14";
$ami-off: "\ea15";
$ami-offline: "\ea16";
$ami-on: "\ea17";
$ami-print: "\ea18";
$ami-wizard: "\ea19";
$ami-bottlenecks: "\ea10";
$ami-format-align-center: "\ea11";
$ami-format-align-left: "\ea12";
$ami-format-align-right: "\ea13";
$ami-record: "\ea0f";
$ami-financial: "\ea0c";
$ami-purchases: "\ea0d";
$ami-sales: "\ea0e";
$ami-result-analysis: "\ea0b";
$ami-list: "\ea0a";
$ami-plant-assignment: "\ea09";
$ami-layers: "\ea06";
$ami-creating-loads: "\ea07";
$ami-haul-back: "\ea08";
$ami-w-cloudy: "\e900";
$ami-w-fog: "\e901";
$ami-w-heat: "\e902";
$ami-w-humidity: "\e903";
$ami-w-lightning: "\e904";
$ami-w-main: "\e905";
$ami-w-night: "\e906";
$ami-w-partlycloudy: "\e907";
$ami-w-precipitation: "\e908";
$ami-w-rainy: "\e909";
$ami-w-snow: "\e90a";
$ami-w-sunny: "\e90b";
$ami-w-windy: "\e90c";
$ami-gas-consumption: "\e90d";
$ami-power-consumption: "\e90e";
$ami-air-flow: "\e90f";
$ami-ammonia: "\e910";
$ami-carbon-dioxide: "\e911";
$ami-humidity: "\e912";
$ami-sensors: "\e913";
$ami-sonar: "\e914";
$ami-threshold: "\e915";
$ami-water: "\e916";
$ami-bell-curve: "\e917";
$ami-condemnations_1: "\e918";
$ami-doa-chicken: "\e919";
$ami-doa-duck: "\e91a";
$ami-doa-swine: "\e91b";
$ami-doa-turkey: "\e91c";
$ami-long-term: "\e91d";
$ami-materials: "\e91e";
$ami-processing-duck: "\e91f";
$ami-processing-orders: "\e920";
$ami-processing-swine: "\e921";
$ami-processing-turkey: "\e922";
$ami-product-breakdown: "\e923";
$ami-pull-mode: "\e924";
$ami-replanning-alt: "\e925";
$ami-replanning: "\e926";
$ami-short-term: "\e927";
$ami-shrink: "\e928";
$ami-source-animals: "\e929";
$ami-supply: "\e92a";
$ami-target: "\e92b";
$ami-unassigned: "\e92c";
$ami-activity: "\e92d";
$ami-analytical-structures: "\e92e";
$ami-bin: "\e92f";
$ami-bonus: "\e930";
$ami-breeder-placement: "\e931";
$ami-breeder: "\e932";
$ami-calc-pay: "\e933";
$ami-capitalizations: "\e934";
$ami-chick-dispose: "\e935";
$ami-chick: "\e936";
$ami-chicken: "\e937";
$ami-compositions: "\e938";
$ami-condemnations: "\e939";
$ami-cost-details: "\e93a";
$ami-ducks: "\e93b";
$ami-egg-dispose: "\e93c";
$ami-egg-regrade: "\e93d";
$ami-egg-residue: "\e93e";
$ami-egg-room: "\e93f";
$ami-egg-sales: "\e940";
$ami-egg-transfer: "\e941";
$ami-eggs: "\e942";
$ami-enviromental: "\e943";
$ami-excel: "\e944";
$ami-farm: "\e945";
$ami-feed-consumed: "\e946";
$ami-feed-delivery: "\e947";
$ami-feed-inventories: "\e948";
$ami-feed: "\e949";
$ami-field-products: "\e94a";
$ami-field: "\e94b";
$ami-fm-main: "\e94c";
$ami-growout-placement: "\e94d";
$ami-growout: "\e94e";
$ami-hallway: "\e94f";
$ami-hatcher: "\e950";
$ami-health: "\e951";
$ami-home: "\e952";
$ami-house: "\e953";
$ami-houses: "\e954";
$ami-interface: "\e955";
$ami-laboratories: "\e956";
$ami-leftovers: "\e957";
$ami-money: "\e958";
$ami-mortality: "\e959";
$ami-mtech-orb: "\e95a";
$ami-po-main: "\e95b";
$ami-processing: "\e95c";
$ami-product-line: "\e95d";
$ami-product-order: "\e95e";
$ami-projection-load: "\e95f";
$ami-projection: "\e960";
$ami-setter: "\e961";
$ami-standards: "\e962";
$ami-surveys: "\e963";
$ami-swine: "\e964";
$ami-temperature: "\e965";
$ami-transfer-alt: "\e966";
$ami-transfer: "\e967";
$ami-turkey: "\e968";
$ami-wastes: "\e969";
$ami-weight: "\e96a";
$ami-wh-main: "\e96b";
$ami-chevron-left: "\e96c";
$ami-chevron-right: "\e96d";
$ami-chevron-down: "\e96e";
$ami-chevron-up: "\e96f";
$ami-slim-left: "\e970";
$ami-slim-up: "\e971";
$ami-slim-right: "\e972";
$ami-slim-down: "\e973";
$ami-arrow-up: "\e974";
$ami-arrow-right: "\e975";
$ami-arrow-down: "\e976";
$ami-arrow-left: "\e977";
$ami-slim-backward: "\e978";
$ami-slim-upward: "\e979";
$ami-slim-forward: "\e97a";
$ami-slim-downward: "\e97b";
$ami-chevron-forward: "\e97c";
$ami-chevron-upward: "\e97d";
$ami-chevron-backward: "\e97e";
$ami-chevron-downward: "\e97f";
$ami-forward: "\e980";
$ami-backward: "\e981";
$ami-drop-down: "\e982";
$ami-drop-right: "\e983";
$ami-drop-up: "\e984";
$ami-drop-left: "\e985";
$ami-drop-up-right: "\e986";
$ami-drop-down-left: "\e987";
$ami-drop-up-left: "\e988";
$ami-drop-down-right: "\e989";
$ami-age: "\e98a";
$ami-alert: "\e98b";
$ami-analytics: "\e98c";
$ami-bell-alt: "\e98d";
$ami-bell: "\e98e";
$ami-big-cards: "\e98f";
$ami-building: "\e990";
$ami-calendar: "\e991";
$ami-cancel: "\e992";
$ami-cart: "\e993";
$ami-chart-bar: "\e994";
$ami-chart-donut: "\e995";
$ami-chart-line: "\e996";
$ami-clone: "\e997";
$ami-collapse: "\e998";
$ami-copy-daily: "\e999";
$ami-copy-date: "\e99a";
$ami-currency: "\e99b";
$ami-dashboard: "\e99c";
$ami-delete: "\e99d";
$ami-disk: "\e99e";
$ami-download: "\e99f";
$ami-drag-horizontal: "\e9a0";
$ami-drag-vertical: "\e9a1";
$ami-ellipsis: "\e9a2";
$ami-excel1: "\e9a3";
$ami-expand: "\e9a4";
$ami-favorrite: "\e9a5";
$ami-file-pdf: "\e9a6";
$ami-file-xls: "\e9a7";
$ami-file: "\e9a8";
$ami-filter-alt-clear: "\e9a9";
$ami-filter-alt: "\e9aa";
$ami-filter-clear: "\e9ab";
$ami-filter: "\e9ac";
$ami-finance: "\e9ad";
$ami-flow-chart-link: "\e9ae";
$ami-flow-chart-unlink: "\e9af";
$ami-flow-data: "\e9b0";
$ami-folder: "\e9b1";
$ami-gantt-alt: "\e9b2";
$ami-gantt: "\e9b3";
$ami-gear: "\e9b4";
$ami-gears: "\e9b5";
$ami-group: "\e9b6";
$ami-hamburger: "\e9b7";
$ami-help: "\e9b8";
$ami-hide: "\e9b9";
$ami-history: "\e9ba";
$ami-home1: "\e9bb";
$ami-image: "\e9bc";
$ami-indent: "\e9bd";
$ami-info-alt: "\e9be";
$ami-info: "\e9bf";
$ami-key: "\e9c0";
$ami-language: "\e9c1";
$ami-lightning: "\e9c2";
$ami-lock: "\e9c3";
$ami-log-off: "\e9c4";
$ami-machine-learning: "\e9c5";
$ami-machinery: "\e9c6";
$ami-map: "\e9c7";
$ami-master-data: "\e9c8";
$ami-minus: "\e9c9";
$ami-more: "\e9ca";
$ami-new-folder: "\e9cb";
$ami-new-page: "\e9cc";
$ami-news: "\e9cd";
$ami-not-reviewed: "\e9ce";
$ami-open: "\e9cf";
$ami-outdent: "\e9d0";
$ami-page: "\e9d1";
$ami-palette: "\e9d2";
$ami-pencil: "\e9d3";
$ami-people: "\e9d4";
$ami-phone-rotate-landscape: "\e9d5";
$ami-phone-rotate-portrait: "\e9d6";
$ami-pin: "\e9d7";
$ami-pinned: "\e9d8";
$ami-pivot-view: "\e9d9";
$ami-play: "\e9da";
$ami-plus: "\e9db";
$ami-power: "\e9dc";
$ami-products: "\e9dd";
$ami-refresh: "\e9de";
$ami-reorder-horizontal: "\e9df";
$ami-reorder-vertical: "\e9e0";
$ami-report-builder: "\e9e1";
$ami-reports: "\e9e2";
$ami-return: "\e9e3";
$ami-reviewed: "\e9e4";
$ami-rocket: "\e9e5";
$ami-rss: "\e9e6";
$ami-ruler: "\e9e7";
$ami-schedule: "\e9e8";
$ami-search: "\e9e9";
$ami-shrink1: "\e9ea";
$ami-small-cards: "\e9eb";
$ami-sort-alpha-a: "\e9ec";
$ami-sort-alpha-d: "\e9ed";
$ami-sort-numeric-a: "\e9ee";
$ami-sort-numeric-d: "\e9ef";
$ami-source-control: "\e9f0";
$ami-speed: "\e9f1";
$ami-success: "\e9f2";
$ami-support: "\e9f3";
$ami-table-large: "\e9f4";
$ami-tasks: "\e9f5";
$ami-tiles: "\e9f6";
$ami-time: "\e9f7";
$ami-tools: "\e9f8";
$ami-uncollapse: "\e9f9";
$ami-ungroup: "\e9fa";
$ami-upload: "\e9fb";
$ami-user-role: "\e9fc";
$ami-user: "\e9fd";
$ami-view: "\e9fe";
$ami-walk: "\e9ff";
$ami-warning: "\ea00";
$ami-windows: "\ea01";
$ami-worker: "\ea02";
$ami-zoom-center: "\ea03";
$ami-zoom-in: "\ea04";
$ami-zoom-out: "\ea05";
