@font-face {
  font-family: "open_sansbold";
  src: url("./OpenSans-Bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sansbold_italic";
  src: url("./OpenSans-BoldItalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sansextrabold";
  src: url("./OpenSans-ExtraBold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sansextrabold_italic";
  src: url("./OpenSans-ExtraBoldItalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sansitalic";
  src: url("./OpenSans-Italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sanslight";
  src: url("./OpenSans-Light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sanslight_italic";
  src: url("./OpenSans-LightItalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sansitalic";
  src: url("./OpenSans-Italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sansregular";
  src: url("./OpenSans-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sanssemibold";
  src: url("./OpenSans-Semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open_sanssemibold_italic";
  src: url("./OpenSans-SemiboldItalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
