@import "./mixins";

//helpers
.h20 {
  height: 20%;
}

.h30 {
  height: 30%;
}

.h40 {
  height: 40%;
}

.h50 {
  height: 50%;
}

.h60 {
  height: 60%;
}

.h70 {
  height: 70%;
}

.h80 {
  height: 80%;
}

.h100,
%h100 {
  height: 100%;
}

.hauto
%auto {
  height: auto;
}

.h1,
%h2 {
  font-size: 48px;
  font-weight: 300;
  letter-spacing: 0;
}

.h2,
%h2 {
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 0;

  @media screen and (max-width: 1400px), screen and (max-height: 900px) {
    font-size: 24px;
  }

  @media screen and (max-width: 1600px), screen and (max-height: 900px) {
    font-size: 28.8px;
  }
}

.h3,
%h3 {
  font-size: 1.75rem !important;
  font-weight: 500;
  letter-spacing: 0;
}

.title,
%title {
  color: var(--hx-emphasis);
  font-size: 24px;
  letter-spacing: 0;
}

.headline,
%headline {
  color: var(--hx-emphasis);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
}

.subtitle,
%subtitle {
  color: var(--hx-emphasis);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.body,
.body1,
%body,
%body1 {
  color: var(--hx-emphasis);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.body2 {
  color: var(--hx-emphasis);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

%body2 {
  color: var(--hx-emphasis);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.button,
%button {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.75px;
  text-transform: uppercase;
}

.caption,
%caption {
  color: var(--hx-emphasis);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.h1,
%h1,
.h2,
%h2
.title,
%title
.headline,
%headline
.subtitle,
%subtitle,
.body,
%body,
.body2,
%body2,
.caption,
%caption {
  &_light {
    color: white;
  }
}

.text-normal {
  color: var(--hx-normal);
}

.col-mb {
  margin-bottom: 32px;
}

[hidden] {
  display: none !important;
}

.no-padding {
  padding: 0 !important;
}

.wizard-on {
  overflow: hidden;
}

.row-flex {
  &.expand {
    flex: 1 1 auto;
    position: relative;
    width: 100%;
    height: calc(100% - 11.5rem);
    @include flexbox;
  }
}
